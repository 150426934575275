<template>
  <div>
    <div>
      <a-card v-if="this.searchHasRight == true" style="margin-bottom: 24px">
        <div class="table-operator">
          <a-form layout="inline">
            <a-row :gutter="5" style="line-height: 3.5">
              <a-col :md="8" :sm="24">
                <a-form-item label="档口编号">
                  <a-input
                    v-model="queryParam.code"
                    placeholder="请输入"
                    @pressEnter="select"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="招商状态">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.bdStatus"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option value="1">可招商</a-select-option>
                    <a-select-option value="2">不可招商</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="客户状态">
                  <a-select
                    style="width: 175px"
                    v-model="queryParam.tenantStatus"
                    @change="select"
                    allowClear
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="item in tenantStatusList"
                      :key="item.id"
                      >{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="门店编号">
                  <a-input
                    v-model="queryParam.facilityCode"
                    @pressEnter="select"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="门店名称">
                  <a-input
                    v-model="queryParam.facilityName"
                    @pressEnter="select"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="客户名称">
                  <a-input
                    v-model="queryParam.tenantName"
                    @pressEnter="select"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="品牌名称">
                  <a-input
                    v-model="queryParam.brandName"
                    @pressEnter="select"
                    placeholder="请输入"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="
                    (advanced && { float: 'right', overflow: 'hidden' }) || {}
                  "
                >
                  <!--                  <a-button type="primary" @click="select">查询</a-button>-->
                  <a-button
                    style="margin-left: 8px"
                    @click="() => ((queryParam = {}), this.init())"
                    >重置</a-button
                  >
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-card>
      <a-card>
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 1300 }"
          @change="changePageSize"
        >
          <!--      <a slot="name" slot-scope="text" href="javascript:;">{{text}}</a>-->
          <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
          <span slot="bdStatus" slot-scope="text, record" class="red">
            {{ record.bdStatus }}
          </span>
          <span
            slot="bdStatus"
            slot-scope="text, record"
            :class="record.bdStatus == '可招商' ? 'green' : 'red'"
          >
            {{ record.bdStatus }}
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <template>
              <a v-if="viewHasRight == true" @click="handleDetail(record)"
                >查看</a
              >&nbsp;&nbsp;
              <a v-if="editHasRight == true" @click="handleEdit(record)">编辑</a
              >&nbsp;&nbsp;
              <a v-if="logHasRight == true" @click="handleActive(record)"
                >操作日志</a
              >
            </template>
          </span>
        </a-table>
        <a-modal
          title="操作日志"
          width="700px"
          v-model="active"
          @ok="handleOk"
          :okButtonProps="{ props: { disabled: true } }"
          :cancelButtonProps="{ props: { disabled: true } }"
          footer
          @change="changePageActionSize"
        >
          <a-table
            rowKey="id"
            :pagination="paginationaction"
            :columns="actioncolumns"
            :dataSource="actiondata"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </a-table>
        </a-modal>
      </a-card>
    </div>
  </div>
</template>
<script>
  
import Bus from "@/bus";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import axios from "axios";

const data = [];
const projects = [];
export default {
  name: "stallsList",
  components: {},
  data() {
    return {
      data,
      visible: false,
      active: false,
      confirmLoading: false,
      parentName: "",
      test: "",
      name: "",
      advanced: "",
      record: "",
      form: this.$form.createForm(this),
      queryParam: {
        code: "",
        bdStatus: undefined,
        tenantStatus: undefined,
        facilityCode: "",
        facilityName: "",
        tenantName: "",
        brandName: "",
        name: ""
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      showDetail: false,
      editDetail: false,
      projects,
      actiondata: [],
      paginationaction: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      // 表头
      columns: [
        {
          title: "档口编号",
          dataIndex: "code",
          key: "code",
          fixed: "left"
        },
        {
          title: "城市",
          dataIndex: "cityName",
          key: "cityName",
          align: "center"
        },
        {
          title: "厨房品牌",
          dataIndex: "brandName",
          key: "brandName",
          align: "center"
        },
        {
          title: "所属门店",
          dataIndex: "facilityName",
          key: "facilityName",
          align: "center"
        },
        {
          title: "档口类型",
          dataIndex: "type",
          key: "type",
          align: "center"
        },
        {
          title: "建筑等级",
          dataIndex: "buildingVO.engineeringLevel",
          key: "buildingVO.engineeringLevel",
          align: "center"
        },
        {
          title: "档口电量",
          dataIndex: "buildingVO.actualElectricity",
          key: "buildingVO.actualElectricity",
          align: "center"
        },
        {
          title: "档口排烟量",
          dataIndex: "buildingVO.actualExhaust",
          key: "buildingVO.actualExhaust",
          align: "center"
        },
        {
          title: "燃气",
          dataIndex: "buildingVO.gas",
          key: "buildingVO.gas",
          align: "center"
        },
        {
          title: "客户名称",
          dataIndex: "tenantVO.name",
          key: "tenantVO.name",
          align: "center"
        },
        {
          title: "客户状态",
          dataIndex: "tenantVO.status",
          key: "tenantVO.status",
          align: "center"
        },
        {
          title: "招商状态",
          // dataIndex: "bdStatus",
          key: "bdStatus",
          scopedSlots: { customRender: "bdStatus" },
          align: "center"
        },
        {
          title: "操作",
          width: "170px",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ],
      //actioncolumns
      actioncolumns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator"
        },
        {
          title: "操作时间",
          dataIndex: "operationTime",
          key: "operationTime",
          width: "130px"
        },
        {
          title: "操作内容",
          dataIndex: "content",
          key: "content"
        }
      ],
      actionId: "",
      //客户状态
      tenantStatusList: [
        {
          id: 1,
          name: "预定中"
        },
        {
          id: 2,
          name: "已预订"
        },
        {
          id: 3,
          name: "签约中"
        },
        {
          id: 4,
          name: "已签约"
        },
        {
          id: 5,
          name: "改造中"
        },
        {
          id: 6,
          name: "待入驻"
        },
        {
          id: 7,
          name: "已入驻"
        },
        {
          id: 8,
          name: "申请退租中"
        },
        {
          id: 9,
          name: "退租中"
        },
        {
          id: 10,
          name: "已退租"
        },
        {
          id: 11,
          name: "已离场"
        }
      ],
      stallsDetail: "",
      stallsEdit: "",
      viewHasRight: true,
      editHasRight: true,
      searchHasRight: true,
      logHasRight: true
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
    this.menuBtnCheck();
  },
  activated() {
    this.init();
    this.menuBtnCheck();
  },
  methods: {
    menuBtnCheck() {
      let btnIdList = [90, 91, 113, 116];
      sessionStorage.setItem("btnId", JSON.stringify(btnIdList));
      axios({
        url:   this.$baseUrl + "menu/btn/check",
        method: "POST",
        data: btnIdList
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.btnId == 90) {
              this.viewHasRight = item.hasRight;
            }
            if (item.btnId == 91) {
              this.editHasRight = item.hasRight;
            }
            if (item.btnId == 113) {
              this.searchHasRight = item.hasRight;
            }
            if (item.btnId == 116) {
              this.logHasRight = item.hasRight;
            }
          });
        }
      });
    },
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "kitchen/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            this.stallsType(item);
            // if(item.bdStatus){
            item.bdStatus = item.bdStatus == 1 ? "可招商" : "不可招商";
            // }
            this.stallsTenantStatus(item);
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.data = [];
        }
        // console.log("res", res);
      });
    },
    stallsType(val) {
      switch (val.type) {
        case 1:
          val.type = "纯外卖";
          break;
        case 2:
          val.type = "堂食";
          break;
        case 3:
          val.type = "功能间";
          break;
        case 4:
          val.type = "储藏间";
          break;
        case 5:
          val.type = "洗碗间";
          break;
        case 6:
          val.type = "其他";
          break;
        default:
          val.type = "无";
      }
      return val.status;
    },
    stallsTenantStatus(val) {
      if (val.tenantVO == null) {
        return;
      } else {
        switch (val.tenantVO.status) {
          case 1:
            val.tenantVO.status = "预定中";
            break;
          case 2:
            val.tenantVO.status = "已预订";
            break;
          case 3:
            val.tenantVO.status = "签约中";
            break;
          case 4:
            val.tenantVO.status = "已签约";
            break;
          case 5:
            val.tenantVO.status = "改造中";
            break;
          case 6:
            val.tenantVO.status = "待入驻";
            break;
          case 7:
            val.tenantVO.status = "已入驻";
            break;
          case 8:
            val.tenantVO.status = "申请退租中";
            break;
          case 9:
            val.tenantVO.status = "退租中";
            break;
          case 10:
            val.tenantVO.status = "已退租";
            break;
          case 11:
            val.tenantVO.status = "已离场";
            break;
          default:
            "无";
        }
      }
      return val.tenantVO.status;
    },
    showModal() {
      this.visible = true;
    },
    handleDetail(val) {
      this.$router.push({
        path: "/stalls/stallsList/stallsListDetail",
        query: { stallsDetailId: val.id, stallsDetailtype: val.type }
      });
    },
    handleEdit(val) {
      this.$router.push({
        path: "/stalls/stallsList/stallsListEdit",
        query: { stallsEditId: val.id }
      });
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          setTimeout(() => {
            this.visible = false;
            this.confirmLoading = false;
            this.$emit("ok", values);
          }, 1500);
        } else {
          this.confirmLoading = false;
        }
      });
    },
    //查询
    select() {
      this.init();
    },
    handleCancel() {
      this.visible = false;
      this.edit = false;
    },
    handleOk() {
      this.$refs.table.refresh();
    },
    //操作日志弹框
    handleActive(val) {
      this.actionId = val;
      this.handleActivecha();
      this.active = true;
    },
    handleActivecha(current, pageSize) {
      axios({
        url:   this.$baseUrl + "kitchen/listKitchenLog",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          kitchenId: this.actionId.id
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.operationTime) {
              item.operationTime = moment(item.operationTime).format(
                "YYYY-MM-DD"
              );
            }
          });
          this.actiondata = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationaction = pagination;
        }
      });
    },
    changePageActionSize(val) {
      this.handleActivecha(val.current, val.pageSize);
    },
    cancelstallsNew() {
      this.init();
      this.editDetail = false;
      this.showDetail = false;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
