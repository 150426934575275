import { render, staticRenderFns } from "./stallsList.vue?vue&type=template&id=3a8f0c03&scoped=true"
import script from "./stallsList.vue?vue&type=script&lang=js"
export * from "./stallsList.vue?vue&type=script&lang=js"
import style0 from "./stallsList.vue?vue&type=style&index=0&id=3a8f0c03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8f0c03",
  null
  
)

export default component.exports